import React, { useEffect, useState } from 'react'
import { Badge, Card, Container, Image, Spinner } from 'react-bootstrap'
import NavBar from './NavBar'
import { database } from '../Firebase'
import qrCode from 'qrcode'
import mjbar from '../pics/mjbar.png'
import traund from '../pics/logo.svg'
import traundBlue from '../pics/logoBlue.svg'
import titulo from '../pics/titulo.png'


const Report = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        total: 0
    })

    useEffect(() => {
        database.lachelaCodes.onSnapshot((docs) => {

            let total = 0

            docs.forEach((doc) => {
                const user = doc.data()
                console.log(user)
                total = +total + +user.counter
            })

            setData({
                total: total
            })
        })
    }, [])

    return (<Container className="main row p-0 m-0" style={{ height: "100vh" }} fluid>
        <Container style={{ height: "60vh", width: "100vw" }} className="main p-0 m-0">
            <NavBar show={true} />
            {!isLoading ? <div style={{ display: "flex", maxWidth: 600, width: "100%", flexDirection: "column", padding: 15 }}>
                <div style={{ margin: "auto" }}>
                    <div style={{ fontSize: 32, fontWeight: 500, color: "#008aff", textAlign: "center", marginBottom: 40 }}>
                         <img src={titulo} alt="event title" style={{ maxWidth: 500, width: "100%", margin: "auto" }} />
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: 30, marginBottom: 30, margin: "auto" }}>
                  
                    <div style={{ display: "flex", padding: 5 }}>
                        <img src={traundBlue} alt="Traund" style={{ width: 120, display: 'block', margin: "auto" }} />
                    </div>
                </div>

                <Card style={{ width: '100%', backgroundColor: "#fbfbfb", marginTop: 25 }}>
                    <Card.Body>
                        <Card.Title> <h5 style={{ textAlign: "center" }}>Total chelas entregadas:  <span style={{ fontWeight: 600 }}>{data.total}</span></h5></Card.Title>
                        {/* <Card.Text>
                            <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
                                <h5>Chelas disponibles: <span style={{ color: "#008aff", fontWeight: 600 }}> {data.canjesLeft}</span></h5>
                            </div>
                        </Card.Text> */}

                    </Card.Body>
                </Card>


            </div> : <Spinner animation="border" role="status" color='primary' />}

        </Container>
    </Container>)
}

export default Report;