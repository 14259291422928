import React, { useRef } from 'react'
import { Button, Container } from 'react-bootstrap'
import NavBar from './NavBar'
// import { Redirect } from 'react-router-dom'
import mjbar from '../pics/mjbarwhite.png'
import traund from '../pics/logo.svg'
import titulo from '../pics/titulowhite.png'
import Form from 'react-bootstrap/Form';

export default function Main() {

  const form = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const code = formData.get("code").toUpperCase()

    window.location.replace(`/start/${code}`)
  }

  return (
    <div style={{ backgroundColor: "#008aff", height: "100vh" }}>
      <NavBar show={false} color={"#001e37"} />
      <Container style={{ height: "80vh" }} fluid className="d-flex flex-column justify-content-center align-items-center">
        {/* {false && (
          role ==="guest" && <Redirect to={'/scan-qr'}/>
        )}
        {false && (
          role ==="host" && <Redirect to={'/make-qr'}/>
        )} */}
        <div style={{ display: "flex", maxWidth: 500, width: "100%", flexDirection: "column" }}>
          <div style={{ fontSize: 32, fontWeight: 500, color: "#008aff", textAlign: "center", marginBottom: 40 }}>
            <img src={titulo} alt="event title" style={{ maxWidth: 500, width: "100%", margin: "auto" }} />
          </div>
          <div style={{ display: "flex" }}>
            
            <div style={{ display: "flex", margin: "auto" }}>
              <img src={traund} alt="Traund" style={{ width: 120, display: 'block', margin: "auto" }} />
            </div>

          </div>
          <div style={{ fontSize: 26, fontWeight: 500, color: "white", textAlign: "center", marginBottom: 15, marginTop: 15 }}>
            Ingresa tu código para canjear tu bebida
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <form onSubmit={handleSubmit} style={{ maxWidth: 400, width: "100%", margin: "auto", marginTop: 15 }}>
              <div style={{ display: "flex", width: "100%" }}>

                {/* <Form.Label>
                  Código de invitado
                  </Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="Ingrese código"
                  aria-label="Código de invitado"
                  name="code"
                  size='lg'
                />

              </div>
              <br />
              <div style={{ display: "flex", width: "100%" }}>
                <Button type="submit" variant="light" size='lg' style={{ width: "100%" }}>Canjear chela</Button>
              </div>
            </form>
          </div>
        </div>

      </Container>

    </div>
  )
}
