import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './qr-attendance/components/Home'
import React from 'react'

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  )
}

export default App;
