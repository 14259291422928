import React, { useState, useEffect } from 'react'
import QrReader from 'react-qr-reader'
import { Button, Container, Spinner } from 'react-bootstrap'
// import { useAuth } from '../Context'
import { database } from '../Firebase'
import qrImg from '../pics/qrImg.png'
import NavBar from './NavBar'
import mjbar from '../pics/mjbar.png'
import titulo from '../pics/titulo.png'
import traundBlue from '../pics/logoBlue.svg'
import Form from 'react-bootstrap/Form';

export default function QRScanner() {
  const [delay, setDelay] = useState(500)
  const [attendance, setAttendance] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false);
  const [showScanner, setShowScanner] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  function handleError(err) {
    console.error(err)
  }

  const handleScan = async (data) => {

    let result = JSON.parse(data)

    if (result) {
      setShowScanner(false)
      setIsLoading(true)
      database.lachelaCodes.doc(result.id).get().then((response) => {

        if (response) {

          const user = response.data();

          if (user.counter < user.max) {

            const updateDocument = {
              ...user,
              counter: +user.counter + 1
            }

            database.lachelaCodes.doc(response.id).update(updateDocument).then((updateResponse) => {

              setAttendance(true)
              setIsSuccess(true)
              setIsLoading(false)
            })

            return
          }
          else {
            setAttendance(true)
            setIsSuccess(false)
            setIsLoading(false)
          }
        }

        else {
          setAttendance(true)
          setIsSuccess(false)
          setIsLoading(false)
        }
      })

    }
  }

  const handleClick = () => {
    setShowScanner(true)
    setAttendance(false)
  }

  return (
    <Container className="main row p-0 m-0" style={{ height: "100vh" }} fluid>
      <Container style={{ height: "60vh", width: "100vw", background: "" }} className="main p-0 m-0">
        <NavBar show={true} />
        {
          !isLoading ? <div style={{ display: "flex", maxWidth: 600, width: "100%", flexDirection: "column", padding: 15 }}>
            <div style={{ margin: "auto" }}>
              <div style={{ fontSize: 32, fontWeight: 500, color: "#008aff", textAlign: "center", marginBottom: 40 }}>
                 <img src={titulo} alt="event title" style={{ maxWidth: 500, width: "100%", margin: "auto" }} />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 50, marginBottom: 50, margin: "auto" }}>
              
              <div style={{ display: "flex", padding: 5 }}>
                <img src={traundBlue} alt="Traund" style={{ width: 120, display: 'block', margin: "auto" }} />
              </div>
            </div>
            {(attendance && !showScanner) &&
              <div className="">
                {isSuccess ?
                  <div style={{ display: "flex", background: "green", color: "white", padding: 15, width: "100%", marginTop: 25, borderRadius: 30 }}>
                    <h5 style={{ padding: 0, margin: 0 }}>QR válido para canjear chela</h5>
                  </div>
                  :
                  <div style={{ display: "flex", background: "red", color: "white", padding: 15, width: "100%",  marginTop: 25, borderRadius: 30 }}>
                    <h5 style={{ padding: 0, margin: 0 }}>QR no válido para canjear chela</h5>
                  </div>
                }
                <div style={{ display: "flex", marginTop: 25, marginBottom: 25}}>
                <Button size='lg' style={{background: "#008aff", color: "white", border: "1px solid #008aff", width: "100%"}} onClick={handleClick}>Escanear nuevamente</Button>
                  </div>
              </div>
            }
            {(!attendance && showScanner) && <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 25, flexDirection: "column" }}>
              <h4 style={{ textAlign: "center", marginBottom: 15 }}>Escanea el QR para validar si es posible canjear la chela</h4>
              <QrReader
                style={{ width: "40vh", height: "30vh" }}
                delay={delay}
                onError={handleError}
                onScan={handleScan}
                className="some"
              />
            </div>}
          </div> : <Spinner animation="border" role="status" color='primary' />
        }
      </Container>
    </Container>
  )
}
